<template>
  <v-sheet>
    <v-tabs
        ref="main-menu"
        background-color="#11b8b8"
        :value="$route.name"
        dark
        next-icon="chevron_right"
        prev-icon="chevron_left"
        show-arrows>

      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab
          :to="{ name: 'TrackingForm' }"
      >
        Tracking
      </v-tab>

      <v-tab
          :to="{ name: 'LeaveForm' }"
      >
        Leave
      </v-tab>

      <v-tab
          :to="{ name: 'WorkFromHomeForm' }"
      >
        WorkFromHome
      </v-tab>

      <v-tab
          :to="{ name: 'ViewSubmission' }"
      >
        Submission
      </v-tab>

      <v-tab
          :to="{ name: 'Summary' }"
      >
        Summary
      </v-tab>

      <v-tab
          :to="{ name: 'Comment' }"
      >
        Feedback
      </v-tab>

      <v-tab
          v-if="employeeDepartment === 'ADMIN'"
          :to="{ name: 'ViewLeave' }">
        Admin
      </v-tab>

      <v-spacer></v-spacer>

      <div v-if="employeeName" class="d-flex align-center pr-4" style="gap: 12px">
        <span class="user">{{ employeeName }}</span>

        <v-btn color="red" small @click="logout">
          <span class="white--text">Logout</span>
        </v-btn>
      </div>
    </v-tabs>
  </v-sheet>

</template>

<script>
import {mapState} from 'vuex'

export default {
  data: () => ({}),
  computed: {
    ...mapState({
      employeeName: state => state.employee.employeeName,
      employeeDepartment: state => state.employee.employeeDepartment
    }),
  },
  methods: {
    logout() {
      this.$store.commit('employee/clearName', '')
      window.location.reload()
    }
  }
}

</script>
<style scoped>
.user {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: white;
}
</style>