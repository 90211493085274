import { requestAndResponse } from "@/plugins/global-fns"

const state = {
    employeeName: "",
    employeeDepartment: ""
}
const getters = {}
const actions = {}
const mutations = {
    setName(state, name) {
        state.employeeName = name
    },
    clearName(state, name) {
        state.employeeName = ""
        state.employeeDepartment = ""
    },
    async setDepartment(state, department) {
        state.employeeDepartment = department
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}