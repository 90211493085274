import { render, staticRenderFns } from "./Manu.vue?vue&type=template&id=3a5fa574&scoped=true"
import script from "./Manu.vue?vue&type=script&lang=js"
export * from "./Manu.vue?vue&type=script&lang=js"
import style0 from "./Manu.vue?vue&type=style&index=0&id=3a5fa574&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a5fa574",
  null
  
)

export default component.exports