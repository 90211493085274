import CryptoJS from 'crypto-js';
import axios from 'axios';
import { encryptionKey, middleware } from '../store/constants';
import store from "@/store";

const iv = CryptoJS.lib.WordArray.random(16);

export function filterAcceptDepartments(data, includeAdmin = false) {
    const departmentOrder = ["ADMIN", "AE", "DEV", "BA", "GRAPHIC", "QA"];

    const departments = {
        "DEV": [],
        "BA": [],
        "GRAPHIC": [],
        "QA": [],
        "AE": [],
        "ADMIN": []
    };

    for (const n of data) {
        if (departments[n.department] !== undefined) {
            if (n.department === "ADMIN" && !includeAdmin) continue;
            let pushValue = n.user || n.name
            departments[n.department].push(pushValue);
        }
    }

    function compareDepartments(a, b) {
        return departmentOrder.indexOf(a) - departmentOrder.indexOf(b);
    }

    const result = departmentOrder.reduce((acc, dept) => {
        return acc.concat(departments[dept].sort(compareDepartments));
    }, []);

    return result;
}
export function encrypt(text, iv) {
    const ivHex = iv.toString(CryptoJS.enc.Hex)
    const key = CryptoJS.enc.Hex.parse(encryptionKey)
    const ivCryptoJS = CryptoJS.enc.Hex.parse(ivHex)
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: ivCryptoJS });

    return {
      encryptedData: encrypted.toString(),
      iv: ivHex
    }
  }
export function decrypt(encryptedData, iv) {
    const keyBytes = CryptoJS.enc.Hex.parse(encryptionKey);
    const ivBytes = CryptoJS.enc.Hex.parse(iv);

    const decryptedBytes = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(encryptedData) },
      keyBytes,
      { iv: ivBytes }
    );
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  }
export async function  requestAndResponse(body){
    let { encryptedData, iv: ivHex } = encrypt(JSON.stringify(body), iv)
    store.commit('general/setLoading', true)
    let res = await axios.post(middleware, { encryptedData: encryptedData, iv: ivHex })
    store.commit('general/setLoading', false)
    let decryptedData = decrypt(res.data.encryptedData, res.data.iv)
    return JSON.parse(decryptedData)
  }